import { createAction, createAsyncAction } from 'typesafe-actions';
import { IFetchTrailsFilterBody } from 'interfaces/trail';
import { Trail, Coordinate } from 'trail-map-components';
import BFGError from 'API/BFGError';

interface IFetchNearbyTrailsAction {
	filter: IFetchTrailsFilterBody;
}

// Fetch nearby trails.
export const fetchNearbyTrailsAction = createAction('explore/fetchNearbyTrailsAction')<IFetchNearbyTrailsAction>();

export const fetchNearbyTrailsAsyncAction = createAsyncAction(
	'explore/fetchNearbyTrailsAsyncActionRequest',
	'explore/fetchNearbyTrailsAsyncActionSuccess',
	'explore/fetchNearbyTrailsAsyncActionFailure',
)<undefined, { trails: Trail[]; excludedSources: string[] }, Error>();

// Fetch trail by id.
export const fetchTrailByIdAction = createAction('explore/fetchTrailByIdAction')<{ trailId: string; token?: string }>();

export const fetchTrailByIdAsyncAction = createAsyncAction(
	'explore/fetchTrailByIdAsyncActionRequest',
	'explore/fetchTrailByIdAsyncActionSuccess',
	'explore/fetchTrailByIdAsyncActionFailure',
)<string, Trail, { trailId: string; error: BFGError }>();

export const clearTrailDetailsErrorAction = createAction('explore/clearTrailDetailsErrorAction')<string>();

// Set search value.
export const setSearchValueAction = createAction('explore/setSearchValueAction')<string>();

// Search trails.
export const searchTrailsAction = createAction('explore/searchTrailsAction')<string>();

export const searchTrailsAsyncAction = createAsyncAction(
	'explore/searchTrailsAsyncActionRequest',
	'explore/searchTrailsAsyncActionSuccess',
	'explore/searchTrailsAsyncActionFailure',
)<undefined, Trail[], undefined>();

// Clear search results.
export const clearSearchResultsAction = createAction('explore/clearSearchResultsAction')();

export const setPOISearch = createAction('explore/setPOISearch')<{ locationName: string | null; location: Coordinate | null }>();

export const setHoveredTrailId = createAction('explore/setHoveredTrailId')<string | null>();

export const setIsMapFlyingAction = createAction('explore/setIsMapFlyingAction')<boolean | null>();

export const recenterToFirstTrailAction = createAction('explore/recenterToFirstTrailAction')<boolean>();

import { combineReducers } from 'redux';
import createReducer from 'state/customCreateReducer';
import {
	addTrailAsyncAction, deleteUserTrailAsyncAction, fetchUserTrailsAsyncAction, saveChangesToMyTrailAsyncAction,
	fetchUserTrailByIdAsyncAction, createTrailFromScratchAsyncAction, createTrailFromExistingTripAsyncAction,
} from './trailsActions';
import { Trail } from 'trail-map-components';

export const trailsReducer = combineReducers({
	userTrails: createReducer<{trails: Trail[] | null; fetched: boolean}>({ trails:null, fetched: false })
		.handleAction(fetchUserTrailsAsyncAction.success, (state, { payload: { trails } }) =>{
			if (!state.fetched) return { trails, fetched: true };
			else return { trails: state.trails || null, fetched: false };
		})
		.handleAction(fetchUserTrailByIdAsyncAction.success, (state, { payload: { trail } }) => {
			if (!trail) {
				return state;
			}
			const previousTrailIndex = (state.trails ?? []).findIndex((t) => t.id === trail.id);

			if (previousTrailIndex < 0) {
				return { ...state, trails: [trail, ...(state.trails ?? [])] };
			}

			return { ...state, trails: [...(state.trails ?? []).slice(0, previousTrailIndex), trail, ...(state.trails ?? []).slice(previousTrailIndex + 1)] };
		})
		.handleAction(addTrailAsyncAction.success, (state, { payload: { trail } }) => {return { trails:state.trails ? [trail, ...state.trails] : [trail], fetched: state.fetched };} )
		.handleAction(deleteUserTrailAsyncAction.success, (state, { payload: trailId }) => {
			return { trails: state?.trails?.filter((trail) => trail.id !== trailId) || null, fetched: state.fetched };
		})
		.handleAction(saveChangesToMyTrailAsyncAction.success, (state, { payload: editingTrail }) => {
			const previousTrailIndex = (state.trails ?? []).findIndex((trail) => trail.id === editingTrail.id);

			if (previousTrailIndex < 0) {
				return { ...state, trails: [editingTrail, ...(state.trails ?? [])] };
			}

			return { ...state, trails: [...(state.trails ?? []).slice(0, previousTrailIndex), editingTrail, ...(state.trails ?? []).slice(previousTrailIndex + 1)] };
		})
		.handleAction(createTrailFromScratchAsyncAction.success, (state, { payload: trail }) => ({ ...state, trails: [trail, ...(state.trails ?? [])] })),
	currentlyFetchingUserTrailIds: createReducer<string[]>([])
		.handleAction(fetchUserTrailByIdAsyncAction.success, (state, { payload: { id } }) => [...state.filter((i) => i !== id)])
		.handleAction(fetchUserTrailByIdAsyncAction.failure, (state, { payload: trailId }) => [...state.filter((i) => i !== trailId)])
		.handleAction(fetchUserTrailByIdAsyncAction.request, (state, { payload: trailId }) => [...state, trailId]),
	currentlyAddingUserTrails: createReducer<string[]>([])
		.handleAction([addTrailAsyncAction.success, addTrailAsyncAction.failure], (state, { payload: { creatingTrailFromTrailId } }) =>
			[...state.filter((i) => i !== creatingTrailFromTrailId)])
		.handleAction(addTrailAsyncAction.request, (state, { payload: { creatingTrailFromTrailId } }) => [...state, creatingTrailFromTrailId]),
	currentlySavingTrail: createReducer(false)
		.handleAction([
			saveChangesToMyTrailAsyncAction.success, saveChangesToMyTrailAsyncAction.failure,
			createTrailFromScratchAsyncAction.success, createTrailFromScratchAsyncAction.failure,
		], () => false)
		.handleAction([saveChangesToMyTrailAsyncAction.request, createTrailFromScratchAsyncAction.request], () => true),
	currentDeletingUserTrails: createReducer<string[]>([])
		.handleAction(deleteUserTrailAsyncAction.request, (state, { payload: trailId }) => ([...state, trailId]))
		.handleAction([deleteUserTrailAsyncAction.success, deleteUserTrailAsyncAction.failure], (state, { payload: trailId }) => [...state.filter((id) => id !== trailId)]),
	currentlyCreatingTrailFromExistingRecording: createReducer(false)
		.handleAction([createTrailFromExistingTripAsyncAction.request], () => true)
		.handleAction([createTrailFromExistingTripAsyncAction.success, createTrailFromExistingTripAsyncAction.failure], () => false),
});

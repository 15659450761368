import ConfigManager from 'API/ConfigManager';
import { requestAPI } from 'API/RequestAPI';
import queryString from 'query-string';
import { IFetchTrailReviews } from 'state/reviews/reviewsActions';

export const getTrailReviews = (filter: IFetchTrailReviews) => {
	const query = queryString.stringify({ count: filter.count, sort: filter.sort });
	const url = `/offroad/trail/${filter.trailId}/reviews/?${query}`;
	const clientId = ConfigManager.getConfig().clientId;

	return requestAPI(
		url,
		'GET',
		filter.continuation
			? { client_id: clientId, 'Content-Type': 'application/json', continuation: filter.continuation }
			: { client_id: clientId, 'Content-Type': 'application/json' },
	);
};

import { createAction, createAsyncAction } from 'typesafe-actions';
import { Trail, Coordinate } from 'trail-map-components';

// Fetch trails nearby my location.
export const fetchTrailsNearbyMyLocationAction = createAction('landing/fetchTrailsNearbyMyLocationAction')<Coordinate>();

export const fetchTrailsNearbyMyLocationAsyncAction = createAsyncAction(
	'landing/fetchTrailsNearbyMyLocationAsyncActionRequest',
	'landing/fetchTrailsNearbyMyLocationAsyncActionSuccess',
	'landing/fetchTrailsNearbyMyLocationAsyncActionFailure',
)<undefined, Trail[], undefined>();

import createReducer from '../customCreateReducer';
import { combineReducers } from 'redux';
import { showFullScreenLoader } from 'state/loading/loadingActions';

export const loadingReducer = combineReducers({
	fullScreenLoader: createReducer<{ counter: number }>({ counter: 0 })
		.handleAction(showFullScreenLoader, (state, { payload: isFetchingAction }) => {
			const counter = state.counter + (isFetchingAction ? 1 : -1);

			if (counter < 0) {
				throw new Error('Fetching counter fell bellow zero (ASSERTION FAILED: fetching === done fetching).');
			}

			return { counter };
		}),
});

import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ISpatialLayer } from 'trail-map-components';
import { fetchAvailableLayersAction, fetchAvailableLayersAsyncAction } from './spatialActions';
import * as spatialApi from 'API/spatialApi';
import toMaterialStyle from 'material-color-hash';
import { AxiosResponse } from 'axios';

const fetchAvailableLayers = function* () {
	try {
		yield put(fetchAvailableLayersAsyncAction.request());

		const layersResponse: AxiosResponse = yield call(spatialApi.getAvailableLayers);
		const layers: ISpatialLayer[] = layersResponse.data;

		layers.forEach((layer, i) => {
			if (!layer.baseColor) {
				const { backgroundColor } = toMaterialStyle(`${i}`, 500);

				layer.baseColor = backgroundColor;
			} else {
				layer.baseColor = `#${layer.baseColor}`;
			}
		});

		yield put(fetchAvailableLayersAsyncAction.success(layers));
	} catch (e) {
		yield put(fetchAvailableLayersAsyncAction.failure());
	}
};

export const spatialSaga = function* () {
	yield all([
		takeLatest(fetchAvailableLayersAction, fetchAvailableLayers),
	]);
};

import { combineReducers } from 'redux';
import createReducer from 'state/customCreateReducer';
import { MapStyle } from 'interfaces/map';
import { changeMapStyleAction, changeVisibleMapLayers } from './mapSettingsActions';

export const mapSettingsReducer = combineReducers({
	mapStyle: createReducer<MapStyle>('offroad')
		.handleAction(changeMapStyleAction, (_, { payload: mapStyle }) => mapStyle),
	visibleMapLayers: createReducer<string[] | null>(null)
		.handleAction(changeVisibleMapLayers, (_, { payload: visibleMapLayers }) => visibleMapLayers),
});

import ConfigManager from './ConfigManager';
import { requestAPI } from './RequestAPI';

export const getBanner = () => {
	const clientId = ConfigManager.getConfig().clientId;

	const url = '/offroad/banners';

	return requestAPI(url, 'GET', { client_id: clientId, 'Content-Type': 'application/json' });
};

import { combineReducers } from 'redux';
import createReducer from '../customCreateReducer';
import { setDialogInformation, clearDialogInformation, InfoDialogData, setNotificationInformation, clearNotificationInformation, INotificationDialogData, setWelcomeToBFGDIalogVisibilty } from './infoActions';

export const infoReducer = combineReducers({
	info: createReducer<InfoDialogData[]>([])
		.handleAction(setDialogInformation, (state, action) => [...state, action.payload])
		.handleAction(clearDialogInformation, (state) => state.slice(0, state.length - 1)),
	notifications: createReducer<INotificationDialogData[]>([])
		.handleAction(setNotificationInformation, (state, action) => [...state, action.payload])
		.handleAction(clearNotificationInformation, (state) => state.slice(0, state.length - 1)),
	welcomeToBFGDialogVisibility: createReducer<boolean>(false)
		.handleAction(setWelcomeToBFGDIalogVisibilty, (_, action) => action.payload),
});

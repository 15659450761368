
export const persistTokenData = (accessToken: string, accessTokenExpiresAt: string, scope: string, refreshToken: string) => {
	try {
		localStorage.setItem('accessToken', accessToken);
		localStorage.setItem('accessTokenExpiresAt', accessTokenExpiresAt);
		localStorage.setItem('scope', scope);
		localStorage.setItem('refreshToken', refreshToken);
	} catch (error) {}
};

export const clearTokenData = () => {
	try {
		localStorage.removeItem('accessToken');
		localStorage.removeItem('accessTokenExpiresAt');
		localStorage.removeItem('scope');
		localStorage.removeItem('refreshToken');
		sessionStorage.removeItem('subscriptionExpiredResponse');
	} catch (e) {}
};

export const isUserLoggedIn = () => {
	try {
		return new Date(`${localStorage.getItem('accessTokenExpiresAt')}`) > new Date();
	} catch (e) {
		return false;
	}
};

export const getRefreshToken = () => {
	try {
		return localStorage.getItem('refreshToken');
	} catch (e) {
		return undefined;
	}
};

export const getAccessToken = () => {
	try {
		return localStorage.getItem('accessToken');
	} catch (e) {
		return undefined;
	}
};

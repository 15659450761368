import { combineReducers } from 'redux';
import createReducer from '../customCreateReducer';
import { fetchPublicUserConfigurationAsyncAction, fetchTrailStyleConfigurationAsyncAction } from 'state/configuration/configurationActions';
import { PublicUserConfiguration } from 'interfaces/configuration';
import { TrailStyleConfiguration } from 'trail-map-components';

export const configurationReducer = combineReducers({
	userConfiguration: createReducer<PublicUserConfiguration | null>(null)
		.handleAction(fetchPublicUserConfigurationAsyncAction.success, (_, { payload: config }) => config),
	isFetchingConfiguration: createReducer(false)
		.handleAction(fetchPublicUserConfigurationAsyncAction.request, () => true)
		.handleAction([fetchPublicUserConfigurationAsyncAction.success, fetchPublicUserConfigurationAsyncAction.failure], () => false),
	trailStyleConfiguration: createReducer<TrailStyleConfiguration[]>([])
		.handleAction(fetchTrailStyleConfigurationAsyncAction.success, (_, { payload: config }) => config),
	isFetchingTrailStyleConfiguration: createReducer(false)
		.handleAction(fetchTrailStyleConfigurationAsyncAction.request, () => true)
		.handleAction([fetchTrailStyleConfigurationAsyncAction.success, fetchTrailStyleConfigurationAsyncAction.failure], () => false),
});

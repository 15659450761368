import { authRequestAPI, requestAPI } from './RequestAPI';

export const getClientToken = (userId: string) => {
	return requestAPI(`/subscriptions/braintree/token/${userId}`, 'GET', { 'Content-Type': 'application/json' });
};

export const createSubscription = (userId: string, paymentMethodNonce: string, planId: string) => {
	return requestAPI('/subscriptions/braintree/subscription', 'POST', { 'Content-Type': 'application/json' }, JSON.stringify({ userId, paymentMethodNonce, planId }));
};

export const checkSubscription = () => {
	return authRequestAPI('/subscriptions/check', 'GET');
};

export const changePaymentMethod = (paymentMethodNonce: string) => {
	const url = '/subscriptions/braintree/change-payment-method';
	const headers = { 'Content-Type': 'application/json' };

	return authRequestAPI(url, 'POST', headers, JSON.stringify({ paymentMethodNonce }));
};

export const cancelSubscription = () => {
	return authRequestAPI('/subscriptions/braintree/subscription', 'DELETE');
};

export const downloadSubscriptionReceipt = (receiptId: string) => {
	return authRequestAPI(`/subscriptions/braintree/receipt/${receiptId}`, 'GET', {}, undefined, 'blob');
};

import { all, call, takeLatest, put } from 'redux-saga/effects';
import * as tripAPI from 'API/tripAPI';
import ITrip from 'interfaces/trip';
import { fetchUserTripsAction, fetchUserTripsAsyncAction } from './tripsActions';
import { ActionType } from 'typesafe-actions';
import { setDialogInformation } from 'state/info/infoActions';
import { AxiosResponse } from 'axios';

const fetchUserTrips = function* (action: ActionType<string | null>) {
	const {
		payload: { token },
	} = action;

	try {
		yield put(fetchUserTripsAsyncAction.request());
		const userTripsResponse: AxiosResponse = yield call(tripAPI.getUserTrips, token);
		const userTrips: ITrip[] = userTripsResponse.data;

		yield put(fetchUserTripsAsyncAction.success({ trips: userTrips, token: userTripsResponse.headers['continuation'] ?? null }));
	} catch (e) {
		yield put(fetchUserTripsAsyncAction.failure(e));
		yield put(setDialogInformation({ title: 'Error', message: e.message }));
	}
};

export const tripsSaga = function* () {
	yield all([takeLatest(fetchUserTripsAction, fetchUserTrips)]);
};

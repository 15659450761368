import { IReview } from 'interfaces/review';
import { combineReducers } from 'redux';
import createReducer from 'state/customCreateReducer';
import { fetchTrailReviewsAsyncAction } from './reviewsActions';

export const reviewsReducer = combineReducers({
	trailReviewData: createReducer<Map<string, { token: string | null; reviews: IReview[] }>>(new Map()).handleAction(
		fetchTrailReviewsAsyncAction.success,
		(state, { payload: { trailReviewData } }) => {
			const key = trailReviewData.keys().next().value;
			const stateValues = state.get(key)?.reviews;
			const fetchedValues = trailReviewData.get(key)?.reviews;
			const fetchedToken = trailReviewData.get(key)?.token;
			const newState: Map<string, { token: string | null; reviews: IReview[] }> = new Map(state);

			if (state.has(key) && stateValues && fetchedValues && !stateValues.some((v) => fetchedValues.indexOf(v) >= 0)) {
				return newState.set(key, { reviews: [...stateValues, ...fetchedValues], token: fetchedToken || null });
			} else {
				if (fetchedValues?.length === 0 || !fetchedValues) return state;
				else return newState.set(key, { reviews: fetchedValues || [], token: fetchedToken || null });
			}
		},
	),
});

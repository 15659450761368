import { all, call, takeLatest, put } from 'redux-saga/effects';
import { fetchSubscriptionOffersAsyncAction, fetchUserSubscriptionAction, fetchUserSubscriptionAsyncAction, fetchSubscriptionOffersAction } from './subscriptionActions';
import { checkSubscription } from 'API/BraintreeAPI';
import { getOffers } from 'API/offerAPI';
import { ActionType } from 'typesafe-actions';
import { AxiosResponse } from 'axios';

const fetchUserSubscription = function* () {
	try {
		yield put(fetchUserSubscriptionAsyncAction.request());
		const subscriptionResponse: AxiosResponse = yield call(checkSubscription);
		const subscription = subscriptionResponse.data;

		yield put(fetchUserSubscriptionAsyncAction.success(subscription));
	} catch (e) {
		yield put(fetchUserSubscriptionAsyncAction.failure());
	}
};

const fetchAllSubscriptionOffers = function* (action: ActionType<typeof fetchSubscriptionOffersAction>) {
	const { payload } = action;
	const { invite, platformType } = payload;

	try {
		yield put(fetchSubscriptionOffersAsyncAction.request());
		const offersResponse: AxiosResponse = yield call(getOffers, platformType, invite);
		const offers = offersResponse.data;

		yield put(fetchSubscriptionOffersAsyncAction.success(offers));
	} catch (e) {
		yield put(fetchSubscriptionOffersAsyncAction.failure());
	}
};

export const subscriptionSaga = function* () {
	yield all([
		takeLatest(fetchUserSubscriptionAction, fetchUserSubscription),
		takeLatest(fetchSubscriptionOffersAction, fetchAllSubscriptionOffers),
	]);
};

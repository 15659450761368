import ITrip from 'interfaces/trip';
import { combineReducers } from 'redux';
import createReducer from 'state/customCreateReducer';
import { fetchUserTripsAsyncAction, setPageNumberAction } from './tripsActions';

export const tripsReducer = combineReducers({
	userTrips: createReducer<ITrip[] | null>(null)
		.handleAction(fetchUserTripsAsyncAction.success, (state, { payload: { trips } }) =>[...(state ?? []), ...trips]),
	continuationToken: createReducer<string | null>(null)
		.handleAction(fetchUserTripsAsyncAction.success, (_, { payload: { token } }) => token),
	page: createReducer(0)
		.handleAction(setPageNumberAction, (_, { payload: { page } }) => page),
	continuationEnd: createReducer(false)
		.handleAction(fetchUserTripsAsyncAction.success, (_, { payload: { token } }) => token === null),
	isFetchingUserTrips: createReducer(false)
		.handleAction([fetchUserTripsAsyncAction.success, fetchUserTripsAsyncAction.failure], () => false)
		.handleAction(fetchUserTripsAsyncAction.request, () => true),
});

import { Coordinate } from 'trail-map-components';

export const loadState = () => {
	try {
		const serializableState = localStorage.getItem('state');

		if (serializableState === null) {
			return undefined;
		}

		return JSON.parse(serializableState);
	} catch (e) {
		return undefined;
	}
};

export const saveState = (state: object) => {
	try {
		const serializedState = JSON.stringify(state);

		localStorage.setItem('state', serializedState);
	} catch (e) {}
};

export const loadIPLocationFromBrowserStorage = () => {
	try {
		const location = sessionStorage.getItem('location');

		if (location) {
			return JSON.parse(location) as Coordinate;
		}

		return null;
	} catch (e) {
		return null;
	}
};

export const saveIPLocationToBrowserStorage = (location: Coordinate) => {
	try {
		sessionStorage.setItem('location', JSON.stringify(location));
	} catch (e) {}
};

export const saveAffiliateCode = (invite: string) => {
	try {
		sessionStorage.setItem('invite', invite);
	} catch (e) {}
};

export const getAffiliateCode = () => {
	try {
		return sessionStorage.getItem('invite');
	} catch (error) {}
};

export const removeAffiliateCode = () => {
	try {
		return sessionStorage.removeItem('invite');
	} catch (error) {
		return undefined;
	}
};

// Get the app popup for mobile devices only when user is logged in.
export const saveHideAppPopup = () => {
	try {
		sessionStorage.setItem('showAppPopup', 'false');
	} catch (e) {}
};

export const getAppPopupState = () => {
	try {
		return sessionStorage.getItem('showAppPopup');
	} catch (error) {}
};

export const saveSpecialOfferRead = () => {
	try {
		sessionStorage.setItem('specialOfferRead', 'true');
	} catch (e) {}
};

export const getSpecialOfferRead = () => {
	try {
		return sessionStorage.getItem('specialOfferRead');
	} catch (e) {}
};

export const removeSpecialOfferRead = () => {
	try {
		return sessionStorage.removeItem('specialOfferRead');
	} catch (e) {}
};

export const loadStateFromBrowserStorage = () => {
	try {
		const serializableState = sessionStorage.getItem('state');

		if (serializableState === null) {
			return undefined;
		}

		return JSON.parse(serializableState);
	} catch (e) {
		return undefined;
	}
};

export const saveStateToBrowserStorage = (state) => {
	try {
		const serializedState = JSON.stringify(state);

		sessionStorage.setItem('state', serializedState);
	} catch (e) {}
};

export const saveSubscriptionExpiredResponse = () => {
	try {
		sessionStorage.setItem('subscriptionExpiredResponse', 'true');
	} catch (e) {}
};

export const getSubscriptionExpiredResponse = () => {
	try {
		return sessionStorage.getItem('subscriptionExpiredResponse');
	} catch (e) {}
};

export const dontShowQuickHelpNotificationAgain = () => {
	try {
		localStorage.setItem('dontShowQuickHelpNotificationAgain', 'true');
	} catch (e) {}
};

export const dontShowQuickHelpNotificationAgainVisible = () => {
	try {
		return localStorage.getItem('dontShowQuickHelpNotificationAgain') === null;
	} catch (e) {}
};

export const saveCurrentEditorDrawMode = (addNewLineModeEnabled: boolean, snapToRoadModeEnabled: boolean) => {
	try {
		sessionStorage.setItem('drawMode', JSON.stringify({ addNewLineModeEnabled, snapToRoadModeEnabled }));
	} catch (e) {}
};

export const getCurrentEditorDrawMode = (): { addNewLineModeEnabled: boolean; snapToRoadModeEnabled: boolean } => {
	const defaultDrawModeState = { addNewLineModeEnabled: false, snapToRoadModeEnabled: false };

	try {
		const drawMode = sessionStorage.getItem('drawMode');

		if (drawMode === null) {
			return defaultDrawModeState;
		}

		return JSON.parse(drawMode);
	} catch (e) {
		return defaultDrawModeState;
	}
};

export const saveDismissedNews = (id: string) => {
	try {
		const existing = getDismissedNews();

		if (existing && existing.length > 0) {
			if (!existing.includes(id)) {
				existing.push(id);
				sessionStorage.setItem('dismissedNews', JSON.stringify(existing));
			}
		} else {
			sessionStorage.setItem('dismissedNews', JSON.stringify([id]));
		}
	} catch (e) {}
};

export const getDismissedNews = (): string[] | undefined => {
	try {
		const data = sessionStorage.getItem('dismissedNews');

		if (data) {
			return JSON.parse(data);
		} else return [];
	} catch (e) {}
};

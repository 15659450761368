import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';
import { trailsReducer } from 'state/trails/trailsReducer';
import { userReducer } from 'state/user/userReducer';
import { tripsReducer } from './trips/tripsReducer';
import { infoReducer } from './info/infoReducer';
import { exploreReducer } from './explore/exploreReducer';
import { reviewsReducer } from './reviews/reviewsReducer';
import { mapSettingsReducer } from './mapSettings/mapSettingsReducer';
import { spatialReducer } from './spatial/spatialReducer';
import { landingReducer } from './landing/landingReducer';
import { userMapLocationReducer } from './userMapLocation/userMapLocationReducer';
import { configurationReducer } from './configuration/configurationReducer';
import { subscriptionReducer } from './subscription/subscriptionReducer';
import { lightboxReducer } from './lightbox/lightboxReducer';
import { loadingReducer } from './loading/loadingReducer';
import { bannerReducer } from './banner/bannerReducer';

export type IRootAction = ActionType<
	typeof import('state/user/userActions') &
	typeof import('state/trails/trailsActions') &
	typeof import('state/trips/tripsActions') &
	typeof import('state/info/infoActions') &
	typeof import('state/explore/exploreActions') &
	typeof import('state/reviews/reviewsActions') &
	typeof import('state/mapSettings/mapSettingsActions') &
	typeof import('state/spatial/spatialActions') &
	typeof import('state/landing/landingActions') &
	typeof import('state/userMapLocation/userMapLocationActions') &
	typeof import('state/configuration/configurationActions') &
	typeof import('state/subscription/subscriptionActions') &
	typeof import('state/lightbox/lightboxActions') &
	typeof import('state/loading/loadingActions') &
	typeof import('state/banner/bannerActions')
>;

declare module 'typesafe-actions' {
	interface Types {
		RootAction: IRootAction;
	}
}

export type IRootState = ReturnType<typeof rootReducer>;

export const rootReducer = combineReducers({
	trails: trailsReducer,
	user: userReducer,
	trips: tripsReducer,
	info: infoReducer,
	explore: exploreReducer,
	reviews: reviewsReducer,
	mapSettings: mapSettingsReducer,
	spatial: spatialReducer,
	landing: landingReducer,
	userMapLocation: userMapLocationReducer,
	configuration: configurationReducer,
	subscription: subscriptionReducer,
	lightbox: lightboxReducer,
	loading: loadingReducer,
	banner: bannerReducer,
});

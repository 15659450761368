import ConfigManager from 'API/ConfigManager';
import { authRequestAPI } from 'API/RequestAPI';

export const getAvailableLayers = () => {
	const { clientId } = ConfigManager.getConfig();

	return authRequestAPI('/spatial/layers', 'GET', { 'Content-Type': 'application/json', client_id: clientId });
};

export const getVectorTileSourceTemplateUrl = (visibleLayers: string[]) => {
	const apiConfig = ConfigManager.getConfig();
	const { ocpApimSubscriptionKey, clientId } = apiConfig;

	return `${apiConfig.baseUrl}/spatial/tiles/{z}/{x}/{y}.pbf?subscription-key=${ocpApimSubscriptionKey}&client_id=${clientId}&layers=${visibleLayers.join(',')}`;
};

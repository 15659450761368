import { createAction, createAsyncAction } from 'typesafe-actions';
import { ISpatialLayer } from 'trail-map-components';

// Fetch availaible layers.
export const fetchAvailableLayersAction = createAction('spatial/fetchAvailableLayersAction')();

export const fetchAvailableLayersAsyncAction = createAsyncAction(
	'spatial/fetchAvailableLayersAsyncActionRequest',
	'spatial/fetchAvailableLayersAsyncActionSuccess',
	'spatial/fetchAvailableLayersAsyncActionFailure',
)<undefined, ISpatialLayer[], undefined>();

import { Attachment } from 'interfaces/trail';
import { Image, Media, Trail } from 'trail-map-components';
import ConfigManager from 'API/ConfigManager';
import queryString from 'query-string';

export const isImageLandscape = (image: Image): boolean => {
	if (image.width && image.height) return image.width > image.height;

	return false;
};

export const isVideoFile = (filename: string) => {
	const videoExtensions = ['mp4', 'mov'];
	const fileExtension = filename.split('.').pop()?.toLowerCase() ?? '';

	return videoExtensions.includes(fileExtension);
};

export const isImageFile = (filename: string) => {
	const imageExtensions = ['bmp', 'png', 'jpg', 'jpeg', 'svg', 'gif'];
	const fileExtension = filename.split('.').pop()?.toLowerCase() ?? '';

	return imageExtensions.includes(fileExtension);
};

const getMediaFromAttachments = (attachments) => {
	return attachments.map((media) => {
		if (isImageFile(media.fileName ?? media.url))
			return ({ ...media, src: media.url, type: 'image', tags: media.tags?.toString() || 'image', orientation: isImageLandscape(media) ? 'landscape' : 'portrait' });
		else if (isVideoFile(media.fileName ?? media.url))
			return ({ ...media, type: 'video', tags: media.tags?.toString() || 'video', orientation: null, src: media.url });
	});
};

export const getGalleryImagesAndVideos = (trail: Trail): Attachment[] => {
	const poiMedia: Attachment[] = [];

	trail.layers?.forEach((layer) => {
		layer.geojson.features.forEach((feature) => {
			const attachments = feature.properties?.attachments || [];

			poiMedia.push(...getMediaFromAttachments(attachments));
		});
	});

	trail.trailData?.features.forEach((feature) => {
		if (feature.properties?.attachments?.length > 0) {
			const attachments = feature.properties?.attachments;

			poiMedia.push(...getMediaFromAttachments(attachments));
		}
	});

	return [...trail.media, ...poiMedia] as Attachment[];
};

export const resizeImageFromUrl = (imageUrl: string, width: number, height?: number) => {
	const config = ConfigManager.getConfig();
	const query = queryString.stringify({ url: imageUrl, width, height, 'subscription-key': config.ocpApimSubscriptionKey });

	const url = `${config.baseUrl}/assets/image-resize?${query}`;

	return url;
};

export const generateThumbnails = (media: Attachment[], width: number, height?: number) => {
	return media?.map((m) => {
		const temp = { ...m };

		if (isImageFile(m.url)) {
			if (m.url) {
				temp.url = resizeImageFromUrl(m.url, width, height);

				return temp;
			}
		}

		return m;
	});
};

export const findCoverMedia = (thumbnails: Attachment[]) => {
	const coverVideoIndex = thumbnails.findIndex((el) => el.tags?.includes('cover') && el.type?.includes('video'));
	const coverPhotoIndex = thumbnails.findIndex((el) => el.tags?.includes('cover') && el.type?.includes('image'));

	if (coverVideoIndex > -1 && coverPhotoIndex > -1) {

		const sortedThumbnails = thumbnails.sort((a, b) => {
			if (a.tags?.includes('cover')) {
				return -1;
			} else if (b.tags?.includes('cover')) {
				return 1;
			}

			return 0;
		});

		sortedThumbnails.sort((a, b) => {
			if (a.tags?.includes('cover') && a.type?.includes('video')) {
				return -1;
			} else if (b.tags?.includes('cover') && b.type?.includes('video')) {
				return 1;
			}

			return 0;
		});

		return sortedThumbnails;

	} else if (coverVideoIndex > -1) {
		return [
			thumbnails[coverVideoIndex],
			...thumbnails.slice(0, coverVideoIndex),
			...thumbnails.slice(coverVideoIndex + 1),
		];

	} else if (coverPhotoIndex > -1) {
		return [
			thumbnails[coverPhotoIndex],
			...thumbnails.slice(0, coverPhotoIndex),
			...thumbnails.slice(coverPhotoIndex + 1),
		];
	} else {
		return thumbnails;
	}
};

export const getBestImageByRatio = (ratio: number, images: Image[]) => {
	images = images.map((image) => {
		image.ratio = (image.width || 1) / (image.height || 1);

		return image;
	});

	let bestImage = images[0];

	for (const image of images) {
		const diff1 = Math.abs(image.ratio! - ratio);
		const diff2 = Math.abs(bestImage.ratio! - ratio);

		if (diff1 < diff2) {
			bestImage = image;
		}
	}

	return bestImage;
};
export const COVER_IMAGE_TAGS = ['cover', 'list', 'preview'];

export const generateCorrectTags = (url: string, media: Media[], files: File[]) => {
	const newFiles = files.map((f) => {
		if (f['url'] === url) {
			Object.defineProperty(f, 'tags', { value: COVER_IMAGE_TAGS, writable: false, configurable: true });

			return f;
		} else {
			Object.defineProperty(f, 'tags', { value: [], writable: false, configurable: true });

			return f;
		}
	});

	const newLocal: Media[] = media.map((m) => {
		if (m.url === url) {

			return { ...m, tags: [...m.tags ?? [], ...COVER_IMAGE_TAGS.filter((tag) => !m.tags?.includes(tag))] };
		} else {
			return { ...m, tags: m.tags?.filter((t) => !COVER_IMAGE_TAGS.includes(t)) };
		}
	});

	return { files: newFiles, localMedia: newLocal };
};

import { combineReducers } from 'redux';
import createReducer from 'state/customCreateReducer';
import {
	fetchNearbyTrailsAsyncAction, fetchTrailByIdAsyncAction,
	setSearchValueAction, searchTrailsAsyncAction,
	clearSearchResultsAction, clearTrailDetailsErrorAction, setPOISearch, setHoveredTrailId, setIsMapFlyingAction, recenterToFirstTrailAction,
} from './exploreActions';
import { Coordinate, Trail } from 'trail-map-components';
import BFGError from 'API/BFGError';
import { deleteUserTrailAsyncAction, saveChangesToMyTrailAsyncAction } from 'state/trails/trailsActions';

export const exploreReducer = combineReducers({
	nearbyTrailsList: createReducer<Trail[] | null>(null)
		.handleAction(fetchNearbyTrailsAsyncAction.success, (state, { payload: { trails, excludedSources } }) => {
			const newlyFetchedTrailIds = trails.map(({ id }) => id);

			return [...(state ?? []).filter((t) => !excludedSources.includes(t.source.type)).filter(({ id }) => !newlyFetchedTrailIds.includes(id)), ...trails].sort(({ name: n1 }, { name: n2 }) =>
				n1.localeCompare(n2),
			);
		})
		.handleAction(saveChangesToMyTrailAsyncAction.success, (state, { payload: editingTrail }) => {
			if (!editingTrail.public) {
				return state?.filter((trail) => trail.id != editingTrail.id) ?? [];
			} else {
				return [...(state ?? []), editingTrail].sort(({ name: n1 }, { name: n2 }) =>
					n1.localeCompare(n2),
				);;
			}
		})
		.handleAction(deleteUserTrailAsyncAction.success, (state, { payload: trailId }) => (state?.filter((trail) => trail.id != trailId) ?? [])),
	isLoadingNearbyTrails: createReducer(false)
		.handleAction([fetchNearbyTrailsAsyncAction.success, fetchNearbyTrailsAsyncAction.failure], () => false)
		.handleAction(fetchNearbyTrailsAsyncAction.request, () => true),
	currentlyFetchingTrailIds: createReducer<string[]>([])
		.handleAction(fetchTrailByIdAsyncAction.success, (state, { payload: { id } }) => [...state.filter((i) => i !== id)])
		.handleAction(fetchTrailByIdAsyncAction.failure, (state, { payload: { trailId } }) => [...state.filter((i) => i !== trailId)])
		.handleAction(fetchTrailByIdAsyncAction.request, (state, { payload: trailId }) => [...state, trailId]),
	trailDetailsError: createReducer<{ [trailId: string]: BFGError | null }>({})
		.handleAction(fetchTrailByIdAsyncAction.failure, (state, { payload: { trailId, error } }) => ({ ...state, [trailId]: error }))
		.handleAction(clearTrailDetailsErrorAction, (state, { payload: trailId }) => Object.entries(state).filter(([id]) => id !== trailId).reduce((pV, [trailId, error]) => ({ ...pV, [trailId]: error }), {})),
	isSearchingTrails: createReducer(false)
		.handleAction([searchTrailsAsyncAction.success, searchTrailsAsyncAction.failure], () => false)
		.handleAction(searchTrailsAsyncAction.request, () => true),
	searchValue: createReducer('')
		.handleAction(setSearchValueAction, (_, { payload: searchValue }) => searchValue),
	searchedTrailResultsList: createReducer<Trail[] | null>(null)
		.handleAction(searchTrailsAsyncAction.success, (_, { payload: searchedTrailResults }) => searchedTrailResults)
		.handleAction([searchTrailsAsyncAction.request, searchTrailsAsyncAction.failure], () => [])
		.handleAction(clearSearchResultsAction, () => null),
	trailsById: createReducer<{ [trailId: string]: Trail }>({})
		.handleAction([fetchTrailByIdAsyncAction.success, saveChangesToMyTrailAsyncAction.success], (state, { payload: trail }) => ({ ...state, [trail.id]: trail })),
	POISearch: createReducer<{ locationName: string | null; location: Coordinate | null } | null>(null)
		.handleAction(setPOISearch, (state, { payload: { locationName, location } }) => ({ locationName, location })),
	hoveredTrailId: createReducer<string | null>(null)
		.handleAction(setHoveredTrailId, (state, { payload: hooveredTrailId }) => hooveredTrailId),
	isMapFlying: createReducer<boolean | null>(null)
		.handleAction(setIsMapFlyingAction, (state, { payload }) => payload),
	recenterToFirstTrail: createReducer<boolean>(false)
		.handleAction(recenterToFirstTrailAction, (_, { payload }) => payload),
});

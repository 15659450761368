import { createAction, createAsyncAction } from 'typesafe-actions';
import { IUserData } from 'interfaces/user';

export const fetchUserDataAction = createAction('user/fetchUserDataAction')();

export const fetchUserDataAsyncAction = createAsyncAction(
	'user/fetchUserDataAsyncActionRequest',
	'user/fetchUserDataAsyncActionSuccess',
	'user/fetchUserDataAsyncActionFailure',
)<undefined, IUserData, undefined>();

export const logout = createAction('user/logout')();

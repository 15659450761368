import ConfigManager from 'API/ConfigManager';
import BFGError from './BFGError';
import { getAccessToken, getRefreshToken, persistTokenData } from 'utils/oauthUtils';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method, ResponseType } from 'axios';

export interface IRequestHeaders {
	[key: string]: string;
}
const axiosInstance = axios.create();
let isRefreshing = false;

axios.interceptors.request.use(
	async (config) => {
		const accessToken = getAccessToken();

	  if (accessToken) {
			config.headers = {
		  ...config.headers,
		  authorization: `Bearer ${accessToken}`,
			};
	  }

	  return config;
	},
	(error) => Promise.reject(error),
);
const interceptor = axiosInstance.interceptors.response.use((response) => response, async (error: AxiosError) => {
	if (error.response?.status !== 401) {

		return error.response;
	}

	axios.interceptors.response.eject(interceptor);

	if (!isRefreshing ) {
		isRefreshing = true;
		const res = await refreshAccessToken();

		if (!res) {
			throw new BFGError('unauthorized', new Error('unauthorized'), error.response.status);
		}

		error.config.headers['Authorization'] = `Bearer ${ res?.data.access_token}`;
		isRefreshing = false;

		return await axiosInstance.request(error.config);
	} else {
		return axiosInstance(error.config);
	}
});

export async function refreshAccessToken() {
	const oldRefreshToken = getRefreshToken();

	if (!oldRefreshToken ) {
		return undefined;
	}
	const config = ConfigManager.getConfig();
	const options: AxiosRequestConfig = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'Ocp-Apim-Subscription-Key': config.ocpApimSubscriptionKey,
		},
		data: `client_id=${config.clientId}&grant_type=refresh_token&refresh_token=${oldRefreshToken}`,
	};
	const response: AxiosResponse = await axiosInstance(`${config.baseUrl}/oauth2/token`, options);

	const { 'access_token': accessToken, 'access_token_expires_at': accessTokenExpiresAt, scope, 'refresh_token': refreshToken } = response.data;

	if (accessToken && accessTokenExpiresAt && scope && refreshToken )
		persistTokenData(accessToken, accessTokenExpiresAt, scope, refreshToken);
	else return undefined;

	return response;
}

export async function requestAPI(url: string, method: Method, headers: IRequestHeaders = {}, body: BodyInit | undefined = undefined, responseType?: ResponseType) {
	const apiConfig = ConfigManager.getConfig();

	const options: AxiosRequestConfig = {
		baseURL: apiConfig.baseUrl,
		responseType: responseType ?? 'json',
		url,
		method,
		headers: {
			...headers,
			'Ocp-Apim-Subscription-Key': apiConfig.ocpApimSubscriptionKey,
		},
		data: body,
	};

	try {
		const response = await axiosInstance.request(options);

		if (!response) {
			throw new BFGError('Connection lost. Please check your internet connection and try again.', new Error('Error'));
		}
		if (response.statusText !== 'OK') {
			const error = response.data;

			if (!isRefreshing && response.status !== 401)
				throw new BFGError(error.message, new Error(error.message), response.status, error.errorCode, error.additionalData);
		}

		return response;

	} catch (e) {
		if (e.message === 'unauthorized') {
			try {
				localStorage.clear();

			} catch (error) {}
			window.location.assign('/');
		}
		throw e;
	}
}

export async function authRequestAPI(url: string, method: Method, headers: IRequestHeaders = {}, body: BodyInit | undefined = undefined, responseType?: ResponseType) {
	const accessToken = getAccessToken();

	if (accessToken) {
		headers.Authorization = `Bearer ${accessToken}`;
	}

	return requestAPI(url, method, headers, body, responseType);
}

export async function ip2LocationRequestAPI(url: string) {
	const { ip2LocationBaseUrl } = ConfigManager.getConfig();
	const ipstackApiKey = process.env.REACT_APP_IPSTACK_API_KEY;

	const requestUrl = `${ip2LocationBaseUrl}/${url}?access_key=${ipstackApiKey}`;

	return fetch(requestUrl);
}

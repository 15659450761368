import { combineReducers } from 'redux';
import createReducer from 'state/customCreateReducer';
import { Trail } from 'trail-map-components';
import { fetchTrailsNearbyMyLocationAsyncAction } from 'state/landing/landingActions';

export const landingReducer = combineReducers({
	nearbyTrails: createReducer<{ trails: Trail[]; fetched: boolean }>({ trails: [], fetched: false })
		.handleAction(fetchTrailsNearbyMyLocationAsyncAction.success, (_, { payload: trails }) => ({ trails, fetched: true })),
	fetchingNearbyTrails: createReducer(true)
		.handleAction([fetchTrailsNearbyMyLocationAsyncAction.success, fetchTrailsNearbyMyLocationAsyncAction.failure], () => false)
		.handleAction(fetchTrailsNearbyMyLocationAsyncAction.request, () => true),
});

import { BannerData } from 'interfaces/banner';
import { combineReducers } from 'redux';
import createReducer from '../customCreateReducer';
import { fetchBannerDataAsyncAction } from 'state/banner/bannerActions';

export const bannerReducer = combineReducers({
	bannerData: createReducer<BannerData[] | null>(null).handleAction(fetchBannerDataAsyncAction.success, (_, { payload } ) => payload),
	isBannerLoading: createReducer(false).handleAction(fetchBannerDataAsyncAction.request, () => true)
		.handleAction([fetchBannerDataAsyncAction.failure, fetchBannerDataAsyncAction.success], () => false),
});

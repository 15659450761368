export interface ICreateClientToken {
    clientToken: string;
}

export enum SubscriptionType {
    Braintree = 'braintree',
    GooglePlay = 'googlePlay',
    AppleStore = 'appleStore',
    AdminCreated = 'adminCreated',
    NoSubscription = 'none'
}

export enum PaymentStatus {
    AwaitingPayment = 'awaitingPayment',
    PaymentSuccessful = 'paymentSuccessfull',
    PaymentError = 'paymentError',
    PaymentNotRequired = 'paymentNotRequired',
    FreeTrail = 'freeTrial',
    Cancelled = 'cancelled',
    Expired = 'expired'
}
export interface ICheckSubscriptionValidity {
    subscriptionExists: boolean;
    subscriptionExpired: boolean;
    paymentReceived: boolean;
    validTo: string;
    lastPaymentDate: string;
    subscriptionSource: SubscriptionType;
    paymentStatus: PaymentStatus;
}

import { ICheckSubscriptionValidity } from 'interfaces/braintree';
import { OfferPlan, PlatformType } from 'interfaces/offer';
import { createAction, createAsyncAction } from 'typesafe-actions';

// Fetch user subscription
export const fetchUserSubscriptionAction = createAction('subscription/fetchUserSubscriptionAction')();

export const fetchUserSubscriptionAsyncAction = createAsyncAction(
	'subscription/fetchUserSubscriptionActionRequest',
	'subscription/fetchUserSubscriptionActionSuccess',
	'subscription/fetchUserSubscriptionActionFailure',
)<undefined, ICheckSubscriptionValidity, undefined>();

// Fetch all subscription offers
export const fetchSubscriptionOffersAction = createAction('subscription/fetchSubscriptionOffersAction')<{platformType: PlatformType; invite?: string}>();

export const fetchSubscriptionOffersAsyncAction = createAsyncAction(
	'subscription/fetchSubscriptionOffersActionRequest',
	'subscription/fetchSubscriptionOffersActionSuccess',
	'subscription/fetchSubscriptionOffersActionFailure',
)<undefined, OfferPlan[], undefined>();

import { combineReducers } from 'redux';
import createReducer from 'state/customCreateReducer';
import { setLighboxDataAction, closeLighboxAction, setLighboxVideoCurrentTimeAction } from './lightboxActions';
import { LightboxProps } from 'components/Ligthbox/Lightbox';

export const lightboxReducer = combineReducers({
	lightboxData: createReducer<LightboxProps | null>(null)
		.handleAction(setLighboxDataAction, (_, { payload: lightboxData }) => lightboxData)
		.handleAction(closeLighboxAction, () => null)
		.handleAction(setLighboxVideoCurrentTimeAction, (state, { payload: currentVideoTime }) => {
			if (state) {
				return { ...state, currentVideoTime };
			}

			return state;
		}),
});

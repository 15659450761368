import ITrip from 'interfaces/trip';
import { createAction, createAsyncAction } from 'typesafe-actions';

interface IFetchUserTripsActions {
	token: string | null;
}

interface ISetPageNumberAction {
	page: number;
}

// Set page
export const setPageNumberAction = createAction('trips/setPageNumberAction')<ISetPageNumberAction>();

// Fetch users trips
export const fetchUserTripsAction = createAction('trips/fetchUserTripsAction')<IFetchUserTripsActions>();

export const fetchUserTripsAsyncAction = createAsyncAction(
	'trips/fetchUserTripsAsyncActionRequest',
	'trips/fetchUserTripsAsyncActionSuccess',
	'trips/fetchUserTripsAsyncActionFailure',
)<undefined, { trips: ITrip[]; token: string | null }, Error>();

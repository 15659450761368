import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from './rootReducer';
import { rootSaga } from './rootSaga';
import { throttle } from 'lodash-es';
import { saveState, loadState, loadStateFromBrowserStorage, saveStateToBrowserStorage } from './persistState';

// Initial state.
const initialState = { ...loadState(), ...loadStateFromBrowserStorage() };

// Create the saga middleware.
const sagaMiddleware = createSagaMiddleware();

// Mount it on the Store.
const store = createStore(rootReducer, initialState, applyMiddleware(sagaMiddleware));

store.subscribe(throttle(() => {
	const state = store.getState();
	const {
		mapSettings: { mapStyle, visibleMapLayers },
		userMapLocation: { mapLocation },
	} = state;

	saveStateToBrowserStorage({ userMapLocation: { mapLocation } });

	return saveState({
		mapSettings: { mapStyle, visibleMapLayers },
	});
}, 500));

// Then run the saga
sagaMiddleware.run(rootSaga);

export default store;

import ConfigManager from 'API/ConfigManager';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

const GoogleAnalytics: React.FC = () => {
	const gaAdsToken = ConfigManager.getConfig().gaAdsToken;

	useEffect(() =>{
		ReactPixel.pageView();

		window.uetq = window.uetq || [];
		window.uetq.push('event', 'page_view', { page_path: window.location.pathname });

		window.gtag('event', 'page_view', {
			'send_to': gaAdsToken,
			'value': window.location.pathname,
			'items': [{
				'origin': document.referrer,
				'destination': window.location.href,
				'start_date': new Date(),
				'end_date': new Date(),
				'google_business_vertical': 'travel',
			}],
		});
	});

	return null;
};

export default withRouter(GoogleAnalytics);

import { getBanner } from 'API/bannerAPI';
import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { fetchBannerAction, fetchBannerDataAsyncAction } from './bannerActions';

const fetchBannerData = function* () {
	try {
		yield put(fetchBannerDataAsyncAction.request());
		const response: AxiosResponse = yield call(getBanner);
		const bannerData = response.data;

		yield put(fetchBannerDataAsyncAction.success(bannerData));
	} catch (e) {
		yield put(fetchBannerDataAsyncAction.failure());
	}
};

export const bannerSaga = function* () {

	yield all([
		takeLatest(fetchBannerAction, fetchBannerData),
	]);
};

import { PublicUserConfiguration } from 'interfaces/configuration';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { TrailStyleConfiguration } from 'trail-map-components';

export interface IUserEnvironmentData {
    osName: string;
    browserName: string;
    browserVersion: string;
}

// Fetch public user configuration
export const fetchPublicUserConfigurationAction = createAction('configuration/fetchPublicUserConfigurationAction')<IUserEnvironmentData>();

export const fetchPublicUserConfigurationAsyncAction = createAsyncAction(
	'configuration/fetchPublicUserConfigurationActionRequest',
	'configuration/fetchPublicUserConfigurationActionSuccess',
	'configuration/fetchPublicUserConfigurationActionFailure',
)<undefined, PublicUserConfiguration, undefined>();

export const fetchTrailStyleConfigurationAction = createAction('configuration/fetchTrailStyleConfigurationAction')();

export const fetchTrailStyleConfigurationAsyncAction = createAsyncAction(
	'configuration/fetchTrailStyleConfigurationActionRequest',
	'configuration/fetchTrailStyleConfigurationActionSuccess',
	'configuration/fetchTrailStyleConfigurationActionFailure',
)<undefined, TrailStyleConfiguration[], undefined>();

import { all, call, takeLatest, put } from 'redux-saga/effects';
import { IUserData } from 'interfaces/user';
import { fetchUserDataAction, fetchUserDataAsyncAction } from './userActions';
import { setDialogInformation } from 'state/info/infoActions';
import * as userAPI from 'API/userAPI';
import { AxiosResponse } from 'axios';

const fetchUserData = function*() {
	try {
		yield put(fetchUserDataAsyncAction.request());
		const userDataResponse: AxiosResponse = yield call(userAPI.getUserData);
		const userDataJson: IUserData = userDataResponse.data;

		yield put(fetchUserDataAsyncAction.success(userDataJson));
	} catch (e) {
		yield put(fetchUserDataAsyncAction.failure());
		yield put(setDialogInformation({ title: 'Error', message: e.message }));
	}
};

export const userSaga = function* () {
	yield all([
		takeLatest(fetchUserDataAction, fetchUserData),
	]);
};

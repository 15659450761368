import { all, call, takeLatest, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import * as reviewAPI from 'API/reviewAPI';
import { fetchTrailReviewsAction, fetchTrailReviewsAsyncAction } from './reviewsActions';
import { IReview } from 'interfaces/review';
import { AxiosResponse } from 'axios';

const fetchTrailReviews = function* (action: ActionType<typeof fetchTrailReviewsAction>) {
	const {
		payload: { trailId, count, sort, continuation },
	} = action;

	try {
		yield put(fetchTrailReviewsAsyncAction.request());
		const trailReviewsResponse: AxiosResponse = yield call(reviewAPI.getTrailReviews, { trailId, count, sort, continuation });
		const trailReviews: IReview[] = trailReviewsResponse.data;

		const trailDataById: Map<string, { reviews: IReview[]; token: string | null }> = new Map();

		trailDataById.set(trailId, { reviews: trailReviews, token: trailReviewsResponse.headers.continuation || null });

		yield put(fetchTrailReviewsAsyncAction.success({ trailReviewData: trailDataById }));
	} catch (e) {
		yield put(fetchTrailReviewsAsyncAction.failure(e));
	}
};

export const reviewsSaga = function* () {
	yield all([takeLatest(fetchTrailReviewsAction, fetchTrailReviews)]);
};

import { PaymentStatus, SubscriptionType } from 'interfaces/braintree';
import { OfferPlan } from 'interfaces/offer';
import { isEqual } from 'lodash-es';
import { combineReducers } from 'redux';
import createReducer from '../customCreateReducer';
import { fetchSubscriptionOffersAsyncAction, fetchUserSubscriptionAsyncAction } from './subscriptionActions';

interface IUserSubscription {
	userPaymentStatus: PaymentStatus;
	hasUserSubscription: boolean;
	isSubscriptionExpired: boolean;
	isFreeUser: boolean;
}

export const subscriptionReducer = combineReducers({
	userSubscription: createReducer<IUserSubscription | null>(null)
		.handleAction(fetchUserSubscriptionAsyncAction.success, (state, { payload: { subscriptionExists, subscriptionExpired, subscriptionSource, paymentStatus } }) => {
			const newSubscriptionState = {
				userPaymentStatus: paymentStatus,
				hasUserSubscription: subscriptionExists && !subscriptionExpired,
				isSubscriptionExpired: subscriptionExpired,
				isFreeUser: !subscriptionExists && subscriptionSource === SubscriptionType.NoSubscription,
			};

			return isEqual(state, newSubscriptionState) ? state : newSubscriptionState;
		}),
	isFetchingSubscription: createReducer(false)
		.handleAction(fetchUserSubscriptionAsyncAction.request, () => true)
		.handleAction([fetchUserSubscriptionAsyncAction.success, fetchUserSubscriptionAsyncAction.failure], () => false),
	offers: createReducer(<OfferPlan[] | null>(null))
		.handleAction(fetchSubscriptionOffersAsyncAction.success, (state, { payload }) => {
			return payload;
		}),
	isFetchingOffers: createReducer(false)
		.handleAction(fetchSubscriptionOffersAsyncAction.request, () => true)
		.handleAction([fetchSubscriptionOffersAsyncAction.success, fetchSubscriptionOffersAsyncAction.failure], () => false),
});

import { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { StoreContext } from 'redux-react-hook';
import store from 'state/store';
import CSSBaseline from '@mui/material/CssBaseline';
import './index.css';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { theme } from 'components/BFGTheme/BFGTheme';
import GoogleAnalytics from 'components/GoogleAnalytics/GoogleAnalytics';
import ConfigManager from 'API/ConfigManager';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

const App = lazy(() => import('components/App/App'));

const {
	firebaseApiKey,
	authDomain,
	projectId,
	storageBucket,
	messagingSenderId,
	appId,
	measurementId } = ConfigManager.getConfig();

const app = initializeApp({
	apiKey: firebaseApiKey,
	authDomain,
	projectId,
	storageBucket,
	messagingSenderId,
	appId,
	measurementId,
});

getAnalytics(app);

ReactDOM.render(
	<StoreContext.Provider value={store}>
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<Router>
					<CSSBaseline />
					<Suspense fallback={''}>
						<App />
					</Suspense>
					<GoogleAnalytics />
				</Router>
			</ThemeProvider>
		</StyledEngineProvider>
	</StoreContext.Provider>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

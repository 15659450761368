import { createTheme, ThemeOptions } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const DRAWER_WIDTH = 472;

export const PRIMARY_BLUE = '#00263E';

export const ACTIVE_RED = '#CC3333';
export const ACTIVE_BLUE = '#003366';
const BEIGE = '#EEEDE9';
const TEXT_DARK = '#212121';
const DARK_BEIGE = '#908F87';
const TITLE_BLUE = '#00263E';
const TITLE_RED = '#F9140C';
const LOGO_RED = '#F9140C';
const CARTOTRACKS = '#922234';
const EASY_TAG = '#003366';
const MEDIUM_TAG = '#D68D35';
const HARD_TAG = '#CC3333';

export const createMyTheme = (options: ThemeOptions) =>
	createTheme({
		...options,
		breakpoints: {
			values: {
				xs: 250,
				sm: 600,
				md: 780,
				tablet: 880,
				planCards: 990,
				lg: 1280,
				xl: 1920,
			},
		},
		typography: {
			h1: {
				fontSize: '32px',
				fontStyle: 'normal',
				fontWeight: 700,
				lineHeight: '42px',
				letterSpacing: 0,
				color: TITLE_BLUE,
			},
			h2: {
				fontSize: '21px',
				fontWeight: 700,
				lineHeight: '27px',
				fontStyle: 'normal',
				color: TITLE_BLUE,
			},
			h3: {
				fontSize: '18px',
				fontStyle: 'normal',
				lineHeight: '23px',
				fontWeight: 700,
				color: TITLE_BLUE,
			},
			h4: {
				fontSize: '16px',
				lineHeight: '21px',
				fontWeight: 700,
				color: TITLE_BLUE,
			},
			fontFamily: [
				'Archivo',
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
			...options.typography,
		},
		palette: {
			primaryBlue: {
				main: PRIMARY_BLUE,
			},
			activeRed: {
				main: ACTIVE_RED,
			},
			activeBlue: {
				main: ACTIVE_BLUE,
			},
			textDark: {
				main: TEXT_DARK,
			},
			beige: {
				main: BEIGE,
			},
			darkBeige: {
				main: DARK_BEIGE,
			},
			titleBlue: {
				main: TITLE_BLUE,
			},
			titleRed: {
				main: TITLE_RED,
			},
			logoRed: {
				main: LOGO_RED,
			},
			difficultyEasy: {
				main: EASY_TAG,
			},
			difficultyMed: {
				main: MEDIUM_TAG,
			},
			difficultyHard: {
				main: HARD_TAG,
			},
			cartotracks: {
				main: CARTOTRACKS,
			},
			...options.palette,
		},
	});

export const theme = createMyTheme({
	palette: {
		primary: {
			main: PRIMARY_BLUE,
		},
		secondary: {
			main: ACTIVE_RED,
		},
		background: {
			default: '#fff',
		},
	},
	components: {
		MuiLink: {
			styleOverrides: {
				root: {
					'textDecoration': 'none',
					':hover': {
						'textDecoration': 'none',
					},
				},
			},

		},
		MuiButton: {
			defaultProps: {
				disableElevation: true,
			},
			styleOverrides: {
				root: {
					'&$disabled': {
						color: 'rgba(255,255,255,0.5) !important',
						backgroundColor: `${DARK_BEIGE} !important`,
					},
					fontFamily: 'Archivo',
					fontSize: '0.875rem',
					fontWeight: 500,
					fontStretch: 'normal',
					fontStyle: 'normal',
					lineHeight: 1.43,
					letterSpacing: 'normal',
					textTransform: 'none',
					padding: '8px 16px',
				},
			},
		},
	},
});

export const useTypographyStyle = makeStyles(() =>
	createStyles({
		bodyText16: {
			color: TEXT_DARK,
		},
		bodyTextBeige16: {
			color: BEIGE,
		},
		bodyTextSecondary16: {
			color: DARK_BEIGE,
		},
		bodyText14: {
			color: TEXT_DARK,
		},
		bodyTextSecondary14: {
			color: DARK_BEIGE,
		},
		bodyText24: {
			color: '#ffffff',
		},
		tagText: {
			textTransform: 'uppercase',
		},
		bfgHeroDesktopBold: {

			fontSize: '3.5rem',
			fontWeight: 800,
			fontStretch: 'normal',
			fontStyle: 'italic',
			lineHeight: 1.5,
			letterSpacing: 'normal',
			textTransform: 'uppercase',
		  },
		  bfgHeroDesktopRegular: {

			fontSize: '3.5rem',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'italic',
			lineHeight: '1.5',
			letterSpacing: 'normal',
			textTransform: 'uppercase',
		},
		  bfgHeroMobileBold: {

			fontSize: '3rem',
			fontWeight: 800,
			fontStretch: 'normal',
			fontStyle: 'italic',
			lineHeight: 1.33,
			letterSpacing: 'normal',
			textTransform: 'uppercase',
		},
		bfgHeroMobileRegular: {

			fontSize: '3rem',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'italic',
			lineHeight: 1.33,
			letterSpacing: 'normal',
			textTransform: 'uppercase',
		},
		  bfgH1Desktop: {

			fontSize: '2.25rem',
			fontWeight: 800,
			fontStretch: 'normal',
			fontStyle: 'italic',
			lineHeight: 1.5,
			letterSpacing: 'normal',
			textTransform: 'uppercase',
			color: TITLE_BLUE,
		},
		  bfgH2Desktop: {

			fontSize: '2rem',
			fontWeight: 800,
			fontStretch: 'normal',
			fontStyle: 'italic',
			lineHeight: 1.5,
			letterSpacing: 'normal',
			textTransform: 'uppercase',
			color: TITLE_BLUE,

		},
		  bfgH1Mobile: {

			fontSize: '2rem',
			fontWeight: 800,
			fontStretch: 'normal',
			fontStyle: 'italic',
			lineHeight: 1.5,
			letterSpacing: 'normal',
			textTransform: 'uppercase',
			color: TITLE_BLUE,

		  },
		  bfgH2Mobile: {

			fontSize:' 1.875rem',
			fontWeight: 800,
			fontStretch: 'normal',
			fontStyle: 'italic',
			lineHeight: 1.6,
			letterSpacing: 'normal',
			textTransform: 'uppercase',
			color: TITLE_BLUE,

		},
		  bfgH3Bold: {

			fontSize: '1.75rem',
			fontWeight: 800,
			fontStretch: 'normal',
			fontStyle: 'italic',
			lineHeight: 1.5,
			letterSpacing: 'normal',
			textTransform: 'uppercase',
			color: TITLE_BLUE,

		},
		  bfgH3Regular: {

			fontSize: '1.75rem',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'italic',
			lineHeight: 1.5,
			letterSpacing: 'normal',
			textTransform: 'uppercase',
			color: TITLE_BLUE,

		},
		  bfgH4Bold: {

			fontSize:' 1.5rem',
			fontWeight: 800,
			fontStretch: 'normal',
			fontStyle: 'italic',
			lineHeight: 1.5,
			letterSpacing: 'normal',
			textTransform: 'uppercase',
			color: TITLE_BLUE,

		  },
		  bfgH4Regular: {

			fontSize: '1.5rem',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'italic',
			lineHeight: 1.5,
			letterSpacing: 'normal',
			textTransform: 'uppercase',
			color: TITLE_BLUE,

		  },
		  bfgH5Bold: {

			fontSize: '1.375rem',
			fontWeight: 800,
			fontStretch: 'normal',
			fontStyle: 'italic',
			lineHeight: 1.5,
			letterSpacing: 'normal',
			textTransform: 'uppercase',
			color: TITLE_BLUE,
		  },
		  bfgH5Regular: {

			fontSize: '1.375rem',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'italic',
			lineHeight: 1.5,
			letterSpacing: 'normal',
			textTransform: 'uppercase',
			color: TITLE_BLUE,
		  },
		 bfgH6Bold: {

			fontSize: '1.25rem',
			fontWeight: 800,
			fontStretch: 'normal',
			fontStyle: 'italic',
			lineHeight: 1.5,
			letterSpacing: 'normal',
			textTransform: 'uppercase',
			color: TITLE_BLUE,
		},
		  bfgH6Regular: {

			fontSize:' 1.25rem',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'italic',
			lineHeight: 1.5,
			letterSpacing: 'normal',
			textTransform: 'uppercase',
			color: TITLE_BLUE,
		},
		  bfgBodyLargeBold: {

			fontSize: '1.125rem',
			fontWeight: 800,
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 1.5,
			letterSpacing: 'normal',
		  },
		  bfgBodyLargeBoldItalic: {

			fontSize: '1.125rem',
			fontWeight: 800,
			fontStretch: 'normal',
			fontStyle: 'italic',
			lineHeight: 1.5,
			letterSpacing: 'normal',
		  },
		 bfgBodyLargeItalic: {

			fontSize:' 1.125rem',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'italic',
			lineHeight: 1.5,
			letterSpacing: 'normal',
		  },
		 bfgBodyLargeRegular: {

			fontSize: '1.125rem',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 1.5,
			letterSpacing: 'normal',
		  },
		  bodyLargeUnderline: {

			fontSize: '1.125rem',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 1.5,
			letterSpacing: 'normal',
		  },
		  bodyMediumBold: {

			fontSize: '1rem',
			FontWeight: 800,
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 1.5,
			letterSpacing: 'normal',
		  },
		  bodyMediumBoldItalic: {

			fontSize: '1rem',
			fontWeight: 800,
			fontStretch: 'normal',
			fontStyle: 'italic',
			lineHeight: 1.5,
			letterSpacing: 'normal',
		  },
		  CTAMediumChipsUnderling: {

			fontSize: '1rem',
			fontWeight: 500,
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 1.5,
			letterSpacing: 'normal',
		  },
		  CTAMediumChips: {

			fontSize: '1rem',
			fontWeight: 500,
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 1.5,
			letterSpacing: 'normal',
		  },
		  bodyMediumItalic: {

			fontSize: '1rem',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'italic',
			lineHeight: 1.5,
			letterSpacing: 'normal',
		  },
		  bodyMediumUnderline: {

			fontSize: '1rem',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 1.5,
			letterSpacing: 'normal',
		  },
		 bodyMediumRegular: {

			fontSize: '1rem',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 1.5,
			letterSpacing: 'normal',
		  },
		  bodySmallBold: {

			fontSize: '0.875rem',
			fontWeight: 800,
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 1.71,
			letterSpacing: 'normal',
		  },
		  bodySmallBoldItalic: {

			fontSize: '0.875rem',
			fontWeight: 800,
			fontStretch: 'normal',
			fontStyle: 'italic',
			lineHeight: 1.71,
			letterSpacing: 'normal',
		  },
		  CTAChipsSmallMediumUnderline: {

			fontSize:' 0.875rem',
			fontWeight: 500,
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 1.43,
			letterSpacing: 'normal',
		  },
		  CTAChipsSmallMedium: {

			fontSize: '0.875rem',
			fontWeight: 500,
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 1.43,
			letterSpacing: 'normal',
		  },
		  bodySmallItalic: {

			fontSize: '0.875rem',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'italic',
			lineHeight: 1.71,
			letterSpacing: 'normal',
		  },
		  bodySmallUnderline: {

			fontSize: '0.875rem',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 1.71,
			letterSpacing: 'normal',
		  },
		  bodySmallRegular: {

			fontSize: '0.875rem',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 1.71,
			letterSpacing: 'normal',
		  },
		  annotationBold: {

			fontSize:' 0.75rem',
			fontWeight: 800,
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 1.5,
			letterSpacing: 'normal',
		  },
		  annotationBoldItalic: {

			fontSize:' 0.75rem',
			fontWeight: 800,
			fontStretch: 'normal',
			fontStyle: 'italic',
			lineHeight: 1.5,
			letterSpacing: 'normal',
		  },
		  AnnotationItalic: {

			fontSize:' 0.75rem',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'italic',
			lineHeight: 1.5,
			letterSpacing: 'normal',
		  },
		  AnnotationUnderline: {

			fontSize: '0.75rem',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 1.5,
			letterSpacing: 'normal',
		  },
		  AnnotationRegular: {

			fontSize:' 0.75rem',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 1.5,
			letterSpacing: 'normal',
		  },
	}), { index: 1 },
);

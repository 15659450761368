import { combineReducers } from 'redux';
import createReducer from 'state/customCreateReducer';
import { Coordinate } from 'trail-map-components';
import { setUserMapLocation } from './userMapLocationActions';

export const userMapLocationReducer = combineReducers({
	mapLocation: createReducer<{ location: Coordinate | null; zoom: number | null }>({ location: null, zoom: null })
		.handleAction(setUserMapLocation, (state, { payload: { location, zoom } }) => {
			return { location, zoom };
		}),
});

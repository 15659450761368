import { createAction } from 'typesafe-actions';

export interface InfoDialogData {
    title: string;
    message: string;
}

export enum NotificationVisibility {
    getTheAppPopup = 'showAppPopup'
}

export enum NotificationButtonType {
    warning = 'warning',
    default = 'default'
}

export interface INotificationDialogData {
    title: string;
    message: string;
    primaryButton: string;
    secondaryButton?: string;
    handlePrimaryButtonClick(): void;
    handleSecondaryButtonClick?(): void;
    autoHide?: boolean;
    persistNotificationVisibility?: NotificationVisibility;
    preventClickAwayClose?: boolean;
    image?: JSX.Element;
    primaryButtonType?: string;
}

export const setDialogInformation = createAction('info/setDialogInformation')<InfoDialogData>();

export const clearDialogInformation = createAction('info/clearDialogInformation')();

export const setNotificationInformation = createAction('info/setNotificationInformation')<INotificationDialogData>();

export const clearNotificationInformation = createAction('info/clearNotificationInformation')();

export const setWelcomeToBFGDIalogVisibilty = createAction('info/setWelcomeToBFGDIalogVisibilty')<boolean>();

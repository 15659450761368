import ConfigManager from 'API/ConfigManager';
import { authRequestAPI } from 'API/RequestAPI';

export const getPublicUserConfiguration = (osName: string, browserName: string, browserVersion: string) => {
	const clientId = ConfigManager.getConfig().clientId;
	const url = '/configuration/public';
	const phonemodel = 'web';
	const systemVersion = browserVersion;
	const system = `${browserName} ${osName}`;
	const appversion = '1.0.0';

	return authRequestAPI(url, 'GET', { client_id: clientId, 'Content-Type': 'application/json', phonemodel, systemVersion, system, appversion });
};

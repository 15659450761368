import ConfigManager from './ConfigManager';
import queryString from 'query-string';
import { requestAPI } from './RequestAPI';
import { PlatformType } from 'interfaces/offer';

export const getOffers = (platform: PlatformType, invitationCode?: string) => {
	const clientId = ConfigManager.getConfig().clientId;
	const query = queryString.stringify({ platform, invitationCode });

	const url = `/subscriptions/offers?${query}`;

	return requestAPI(url, 'GET', { client_id: clientId, 'Content-Type': 'application/json' });
};

import { IReview } from 'interfaces/review';
import { createAction, createAsyncAction } from 'typesafe-actions';

export interface IFetchTrailReviews {
	trailId: string;
	count: number;
	sort: 'best' | 'latest';
	continuation: string | null;
}

export const fetchTrailReviewsAction = createAction('reviews/fetchTrailsReviews')<IFetchTrailReviews>();
export const fetchTrailReviewsAsyncAction = createAsyncAction(
	'reviews/fetchTrailReviewsAsyncActionRequest',
	'reviews/fetchTrailReviewsAsyncActionSuccess',
	'reviews/fetchTrailReviewsAsyncActionFailure',
)<undefined, { trailReviewData: Map<string, { token: string | null; reviews: IReview[] }> }, Error>();

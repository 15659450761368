import { all, call, takeLatest, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { fetchPublicUserConfigurationAction, fetchPublicUserConfigurationAsyncAction, fetchTrailStyleConfigurationAction, fetchTrailStyleConfigurationAsyncAction } from 'state/configuration/configurationActions';
import { getPublicUserConfiguration } from 'API/configAPI';
import { getTrailStyleConfiguration } from 'API/trailAPI';
import { AxiosResponse } from 'axios';

const fetchPublicUserConfiguration = function* (action: ActionType<typeof fetchPublicUserConfigurationAction>) {
	const { payload: { osName, browserName, browserVersion } } = action;

	try {
		yield put(fetchPublicUserConfigurationAsyncAction.request());
		const configResponse: AxiosResponse = yield call(getPublicUserConfiguration, osName, browserName, browserVersion);
		const config: any = configResponse.data;

		yield put(fetchPublicUserConfigurationAsyncAction.success(config));
	} catch (e) {
		yield put(fetchPublicUserConfigurationAsyncAction.failure());
	}
};

const fetchTrailStyleConfiguration = function* () {
	try {
		yield put(fetchTrailStyleConfigurationAsyncAction.request());
		const trailConfigurationResponse: AxiosResponse = yield call(getTrailStyleConfiguration);
		const trailConfiguration = trailConfigurationResponse.data;

		yield put(fetchTrailStyleConfigurationAsyncAction.success(trailConfiguration));
	} catch (e) {
		yield put(fetchTrailStyleConfigurationAsyncAction.failure());
	}
};

export const configurationSaga = function* () {
	yield all([
		takeLatest(fetchPublicUserConfigurationAction, fetchPublicUserConfiguration),
		takeLatest(fetchTrailStyleConfigurationAction, fetchTrailStyleConfiguration),
	]);
};

import { authRequestAPI, requestAPI } from 'API/RequestAPI';
import queryString from 'query-string';
import ConfigManager from 'API/ConfigManager';
import { IUserData } from 'interfaces/user';

export const getUserData = () => {
	const query = queryString.stringify({ ignoreLocations: true });
	const url = `/users/me?${query}`;

	return authRequestAPI(url, 'GET');
};

export type RegisterType = 'subscription' | 'password';

export const registerUser = (userData: IUserData, registerType: RegisterType, invitationCode?: string) => {
	const { clientId } = ConfigManager.getConfig();
	const payload = {
		...userData,
		registerType,
		invitationCode,
	};

	return requestAPI('/users/register', 'POST', { 'client_id': clientId, 'Content-Type': 'application/json' }, JSON.stringify(payload));
};
export const isInvitationValid = (invitation: string) => {
	const url = `/users/invitations/${invitation}`;
	const { clientId } = ConfigManager.getConfig();

	return requestAPI(url, 'GET', { client_id: clientId, 'Content-Type': 'application/json' });
};

export const verifyUserSubscription = (token: string) => {
	const { clientId } = ConfigManager.getConfig();
	const payload = { token };

	return requestAPI('/users/verify/user-subscription', 'POST', { 'client_id': clientId, 'Content-Type': 'application/json' }, JSON.stringify(payload));
};

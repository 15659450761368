import config from 'config/api-config.json';

interface Config {
	baseUrl: string;
	clientId: string;
	ocpApimSubscriptionKey: string;
	ip2LocationBaseUrl: string;
	mixpanelToken: string;
	gaTagToken: string;
	gaAdsToken: string;
	facebookToken: string;
    pageUrl: string;
	iosDownloadLink: string;
	androidDownloadLink: string;
	firebaseApiKey: string;
	authDomain: string;
	projectId: string;
	storageBucket: string;
	messagingSenderId: string;
	appId: string;
	measurementId: string;
}

export default class ConfigManager {
	static getConfig(): Config {
		let env: 'develop' | 'production' = 'develop';

		if (process.env.REACT_APP_ENVIRONMENT === 'production') {
			env = 'production';
		}

		return {
			baseUrl: config.env[env].baseUrl,
			clientId: config.env[env].clientId,
			ocpApimSubscriptionKey: config.env[env].ocpApimSubscriptionKey,
			ip2LocationBaseUrl: config.env[env].ip2LocationBaseUrl,
			mixpanelToken: config.env[env].mixpanelToken,
			gaTagToken: config.env[env].gaTagToken,
			gaAdsToken: config.env[env].gaAdsToken,
			facebookToken: config.env[env].facebookToken,
			pageUrl: config.env[env].pageUrl,
			iosDownloadLink: config.env[env].iosDownloadLink,
			androidDownloadLink: config.env[env].androidDownloadLink,
			firebaseApiKey: config.env[env].firebaseApiKey,
			authDomain: config.env[env].authDomain,
			projectId: config.env[env].projectId,
			storageBucket: config.env[env].storageBucket,
			messagingSenderId: config.env[env].messagingSenderId,
			appId: config.env[env].appId,
			measurementId: config.env[env].measurementId,
		};
	}

	static initGoogleTags(): void {
		if (process.env.REACT_APP_ENVIRONMENT === 'production') {
			window.gtag('config', config.env['production'].gaTagToken, { send_page_view: false });
			window.gtag('config', config.env['production'].gaAdsToken, { send_page_view: false });
			window.gtag('config', 'AW-11272690458');

		} else {
			window.gtag('config', config.env['develop'].gaTagToken, { send_page_view: false });
		}
	}
}

interface IAdditionalData {
    trailParkIds: string[];
}
export default class BFGError extends Error {
    data: Error;
    statusCode: number;
    errorCode?: string;
    additionalData?: IAdditionalData;

    constructor(message = 'Oops, something went wrong (undocumented error).', data: Error, statusCode?: number, errorCode?: string, additionalData?:IAdditionalData) {
    	super(message);
    	this.data = data;
    	this.statusCode = statusCode ?? 0;
    	this.errorCode = errorCode;
    	this.additionalData = additionalData;
    }
}

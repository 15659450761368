import { all, call } from 'redux-saga/effects';
import { trailsSaga } from 'state/trails/trailsSaga';
import { userSaga } from 'state/user/userSaga';
import { exploreSaga } from './explore/exploreSaga';
import { reviewsSaga } from './reviews/reviewsSaga';
import { tripsSaga } from './trips/tripsSaga';
import { spatialSaga } from './spatial/spatialSaga';
import { landingSaga } from './landing/landingSaga';
import { configurationSaga } from './configuration/configurationSaga';
import { subscriptionSaga } from './subscription/subscriptionSaga';
import { bannerSaga } from './banner/bannerSaga';

export function* rootSaga() {
	yield all([
		call(trailsSaga),
		call(userSaga),
		call(tripsSaga),
		call(exploreSaga),
		call(reviewsSaga),
		call(spatialSaga),
		call(landingSaga),
		call(configurationSaga),
		call(subscriptionSaga),
		call(bannerSaga),
	]);
}

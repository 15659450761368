import { all, call, takeLatest, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {
	fetchNearbyTrailsAction, fetchNearbyTrailsAsyncAction,
	fetchTrailByIdAction, fetchTrailByIdAsyncAction,
	searchTrailsAction, searchTrailsAsyncAction,
} from './exploreActions';
import * as trailAPI from 'API/trailAPI';
import { Trail } from 'trail-map-components';
import { setDialogInformation } from 'state/info/infoActions';
import { AxiosResponse } from 'axios';

const fetchNearbyTrails = function* (action: ActionType<typeof fetchNearbyTrailsAction>) {
	const {
		payload: { filter },
	} = action;

	try {
		yield put(fetchNearbyTrailsAsyncAction.request());
		const nearbyTrailsResponse: AxiosResponse = yield call(trailAPI.getNearbyTrails, filter);
		const nearbyTrails: Trail[] = nearbyTrailsResponse.data;

		yield put(fetchNearbyTrailsAsyncAction.success({ trails: nearbyTrails, excludedSources: filter.excludedSourceTypes ?? [] }));
	} catch (e) {
		yield put(fetchNearbyTrailsAsyncAction.failure(e));
		yield put(setDialogInformation({ title: 'Error', message: e.message }));
	}
};

const fetchTrailById = function* (action: ActionType<typeof fetchTrailByIdAction>) {
	const token = action.payload.token;
	const trailId = action.payload.trailId;

	try {
		yield put(fetchTrailByIdAsyncAction.request(trailId));
		const trailResponse: AxiosResponse = yield call(trailAPI.getTrailById, trailId, token);
		const trail: Trail = trailResponse.data;

		yield put(fetchTrailByIdAsyncAction.success(trail));
	} catch (error) {
		yield put(fetchTrailByIdAsyncAction.failure({ trailId, error }));
	}
};

const searchTrails = function* (action: ActionType<typeof searchTrailsAction>) {
	const { payload: searchValue } = action;

	try {
		yield put(searchTrailsAsyncAction.request());

		const searchTrailsResponse: AxiosResponse = yield call(trailAPI.searchTrails, searchValue);
		const trailsJSON: Trail[] = searchTrailsResponse.data;

		yield put(searchTrailsAsyncAction.success(trailsJSON));
	} catch (e) {
		yield put(searchTrailsAsyncAction.failure());
	}
};

export const exploreSaga = function* () {
	yield all([
		takeLatest(fetchNearbyTrailsAction, fetchNearbyTrails),
		takeEvery(fetchTrailByIdAction, fetchTrailById),
		takeEvery(searchTrailsAction, searchTrails),
	]);
};

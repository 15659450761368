import { all, call, takeLatest, put } from 'redux-saga/effects';
import { Trail } from 'trail-map-components';
import { IFetchTrailsFilterBody } from 'interfaces/trail';
import { ActionType } from 'typesafe-actions';
import { fetchTrailsNearbyMyLocationAction, fetchTrailsNearbyMyLocationAsyncAction } from 'state/landing/landingActions';
import { getNearbyTrails } from 'API/trailAPI';
import { AxiosResponse } from 'axios';

const fetchTrailsNearbyMyLocation = function* (action: ActionType<typeof fetchTrailsNearbyMyLocationAction>) {
	const { payload: { lat, lon } } = action;

	try {
		yield put(fetchTrailsNearbyMyLocationAsyncAction.request());
		const filter: IFetchTrailsFilterBody = {
			latitude: lat,
			longitude: lon,
			radius: 1000000,
			sortProperty: 'distance',
			sortOrder: 'ASC',
			verified: true,
		};
		const trailsResponse: AxiosResponse = yield call(getNearbyTrails, filter, 20);
		const trails: Trail[] = trailsResponse.data;

		yield put(fetchTrailsNearbyMyLocationAsyncAction.success(trails));
	} catch (e) {
		yield put(fetchTrailsNearbyMyLocationAsyncAction.failure());
	}
};

export const landingSaga = function* () {
	yield all([
		takeLatest(fetchTrailsNearbyMyLocationAction, fetchTrailsNearbyMyLocation),
	]);
};
